import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displayStates: {
    overlay: false,
    showNotice: false,
    popRequest: false,
    hideSidebar: false,
    viewProject: false,
    servicerequest: false,
    morejobs: false,
    showjob: false,
    showApplication: false,
    showApplyForm: false,
    jobId: "",
    projectId: "",
    ratings: "",
    projectSearch: "",
    showProjectDetails: false,
    projectFilter: "",
    rateConsumer: false,
    extensionForm: false,
    disputeDashboard: false,
    editServices: false,
    showMobileRefer: false,
  },
};

export const overlaySlice = createSlice({
  name: "overlay",
  initialState: initialState,
  reducers: {
    setOverlay: (state, action) => {
      state.displayStates.overlay = action.payload;
    },
    setShowNotice: (state, action) => {
      state.displayStates.showNotice = action.payload;
    },
    setPopRequest: (state, action) => {
      state.displayStates.popRequest = action.payload;
    },
    setHideSidebar: (state, action) => {
      state.displayStates.hideSidebar = action.payload;
    },
    setViewProject: (state, action) => {
      state.displayStates.viewProject = action.payload;
    },
    setShowServiceRequest: (state, action) => {
      state.displayStates.servicerequest = action.payload;
    },
    setSeeMoreJobs: (state, action) => {
      state.displayStates.morejobs = action.payload;
    },
    setShowApplyForm: (state, action) => {
      state.displayStates.showApplyForm = action.payload;
    },
    setJobDetails: (state, action) => {
      state.displayStates.showjob = action.payload;
    },
    setShowApplication: (state, action) => {
      state.displayStates.showApplication = action.payload;
    },
    setJobDetailsId: (state, action) => {
      state.displayStates.jobId = action.payload;
    },
    setProjectId: (state, action) => {
      state.displayStates.projectId = action.payload;
    },
    setShowProjectDetails: (state, action) => {
      state.displayStates.showProjectDetails = action.payload;
    },
    setProjectFilter: (state, action) => {
      state.displayStates.projectFilter = action.payload;
    },
    setProjectSearch: (state, action) => {
      state.displayStates.projectSearch = action.payload;
    },
    setRateConsumer: (state, action) => {
      state.displayStates.rateConsumer = action.payload;
    },
    setExtensionForm: (state, action) => {
      state.displayStates.extensionForm = action.payload;
    },
    setSaveRatings: (state, action) => {
      state.displayStates.ratings = action.payload;
    },
    setDisputeDashboard: (state, action) => {
      state.displayStates.disputeDashboard = action.payload;
    },
    setEditServices: (state, action) => {
      state.displayStates.editServices = action.payload;
    },
    setShowMobileRefer: (state, action) => {
      state.displayStates.showMobileRefer = action.payload;
    },
  },
});

export const {
  setOverlay,
  setProjectId,
  setShowProjectDetails,
  setShowServiceRequest,
  setHideSidebar,
  setViewProject,
  setSeeMoreJobs,
  setJobDetails,
  setJobDetailsId,
  setShowApplyForm,
  setShowApplication,
  setShowNotice,
  setPopRequest,
  setProjectFilter,
  setRateConsumer,
  setProjectSearch,
  setExtensionForm,
  setSaveRatings,
  setDisputeDashboard,
  setEditServices,
  setShowMobileRefer,
} = overlaySlice.actions;
