import React, { useState } from "react";
import { Button } from "../../../../../../components/ui";

const LogoutModal = ({ showLogout, setShowLogout }) => {
  if (!showLogout) return null;

  const handleCancel = () => {
    setShowLogout(false);
  };

  const handleLogout = () => {};

  return (
    <>
      <div className="fixed z-[99] h-screen w-screen bg-black bg-opacity-35" />
      <div className="fixed top-[35%] z-[999] mx-5 rounded-[5px] bg-primary md:left-[35%] md:mx-0 md:w-[30%]">
        <span
          className="absolute right-6 top-2 text-xl font-medium hover:cursor-pointer md:right-10"
          onClick={handleCancel}
        >
          x
        </span>
        <div className="flex flex-col items-center justify-center gap-y-4 py-6">
          <span className="font-medium text-lightblue md:text-lg">Logging out?</span>
          <span className="text-center font-medium text-[#181818] md:text-lg">
            Are you sure you want to log out from your account?
          </span>
          <hr color="#AEAEAE" className="w-full" />
          <div className="flex w-1/2 items-center justify-between md:text-xl">
            <div className="flex items-center justify-center text-center">
              <span onClick={handleCancel} className="text-lightblue hover:cursor-pointer">
                No
              </span>
            </div>
            <div>
              <span onClick={handleLogout} className="text-[#FF0000] hover:cursor-pointer">
                Yes
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogoutModal;
