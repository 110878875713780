import React, { useState } from "react";
import Container from "../../../container";
import { useNavigate } from "react-router-dom";
import ReuseableDropdown from "../../../projects/shared/reuseable-dropdown/index.";
import { jobsData } from "../../data";
import TrackJobsCard from "../cards";
const TrackJobs = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(1);
  const [newestSort, setNewestSort] = useState(false);
  const [categorySort, setCategorySort] = useState(false);
  const [statusSort, setStatusSort] = useState(false);

  return (
    <Container>
      <div className="px-8 py-20 md:px-10">
        <div className="justify-between md:flex">
          <h1 className="text-center text-[20px] font-medium md:text-start md:text-[25px]">
            My Jobs
          </h1>
          <div
            className="hidden items-center rounded-md border-[1px] border-[#0096C1] bg-white px-2 py-1 text-[16px] text-[#0096C1] md:flex"
            onClick={() => back}
          >
            Post new job
          </div>
        </div>
        <div className=" mt-5 flex w-full cursor-pointer rounded-md border-2">
          <div
            className={`${activeIndex === 1 ? "bg-[#0096C1] text-white" : "bg-white text-[#0096C1]"} w-[50%] rounded-md px-2 py-3 text-center`}
            onClick={() => setActiveIndex(1)}
          >
            Opened
          </div>
          <div
            className={`${activeIndex === 2 ? "bg-[#0096C1]  text-white" : "bg-white text-[#0096C1]"} w-[50%] rounded-md px-2 py-3 text-center`}
            onClick={() => setActiveIndex(2)}
          >
            Closed
          </div>
        </div>
        <div className="hide-scrollbar mt-7 flex items-center gap-4 overflow-x-auto md:overflow-hidden">
          <span>Sort:</span>
          <ReuseableDropdown
            label="Newest"
            isOpen={newestSort}
            handleToggle={() => setNewestSort(!newestSort)}
            options={["Newest first", "Oldest first"]}
          />

          <ReuseableDropdown
            label="Category"
            isOpen={categorySort}
            handleToggle={() => setCategorySort(!categorySort)}
            options={["All Service categories"]}
          />

          <ReuseableDropdown
            label="Status"
            isOpen={statusSort}
            handleToggle={() => setStatusSort(!statusSort)}
            options={["All", "Unresolved", "Resolving", "Resolved"]}
          />
        </div>

        <div className="mt-7 grid cursor-pointer grid-cols-1 md:grid-cols-1">
          {jobsData
            .filter((job) => (activeIndex === 1 ? job.status === "open" : job.status === "closed"))
            .map((job) => (
              <TrackJobsCard key={job.id} title={job.title} loation={job.loation} posted_date={job.posted_date} budget={job.budget} onClick={() => navigate(`/jobs/${job.id}`)} />
            ))}
        </div>
        <div
          className="my-9 flex cursor-pointer items-center justify-center rounded-md border-[1px] border-[#0096C1] bg-white px-2 py-3 text-center text-[16px] text-[#0096C1] md:hidden"
          onClick={() => back}
        >
          Post new job
        </div>
      </div>
    </Container>
  );
};

export default TrackJobs;
