import React from "react";
import { Layout } from "../../components";
import { BannedAccounts, DisputeDetails, MainContent, Navbar } from "./_components";
import { useSelector } from "react-redux";
import Modal from "./_components/dispute-details/_components/modal";
import SuperAdminDisputeMain from "./dispute";

const SuperAdminDispute = () => {
  const showDetails = useSelector((state) => state.modalOverlay.states.showDispute);
  const openRequest = useSelector((state) => state.modalOverlay.states.showResponse);
  const showBannedAccounts = useSelector((state) => state.modalOverlay.states.showBannedAccounts);
  return (
    <Layout>
      {showBannedAccounts && <BannedAccounts />}
      {openRequest && <div className="absolute inset-0 z-50 h-[100vh] bg-black bg-opacity-25" />}
      {openRequest && (
        <div className="absolute left-[40%] right-[50%] top-[30%] z-[999] w-[30%]">
          <Modal />
        </div>
      )}
      {showBannedAccounts || <Navbar />}
      {!(showDetails || showBannedAccounts) && <SuperAdminDisputeMain />}
      {/* {showDetails && <DisputeDetails />} */}
    </Layout>
  );
};

export default SuperAdminDispute;
