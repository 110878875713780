import "./App.css";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import {
  AboutUs,
  BeAVendor,
  CatalogAndPicture,
  Home,
  Proceed,
  ServiceDetails,
  Services,
  Login,
  Signup,
  Dashboard,
  PersonalDetails,
  PricingDetails,
} from "./pages";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { category } from "./features/categorySlice";
import { useLocation } from "react-router-dom";
import ForgotPassword from "./pages/login/_components/forgot-password";
import EnterOtp from "./pages/login/_components/enter-otp";
import CreatePassword from "./pages/login/_components/create-password";
import DashService from "./pages/dashboard/service";
import VendorDashboardJobs from "./pages/vendor-dashboard/_pages/jobs";
import VendorDashboardHome from "./pages/vendor-dashboard/_pages/home";
import VendorDashboardChat from "./pages/vendor-dashboard/_pages/chat";
import DesktopContent from "./pages/dashboard/cardContent/desktopView";
import CardContent from "./pages/dashboard/cardContent";
import AllReconmmendedVendors from "./pages/dashboard/recommendedVendors";
import VendorDashboardProjects from "./pages/vendor-dashboard/_pages/projects";
import Chats from "./pages/dashboard/chats";
import Request from "./pages/dashboard/requestService/request";
import ServiceRequest from "./pages/dashboard/serviceRequest";
import BookingProcess from "./pages/dashboard/bookingProcess";
import BookService from "./pages/dashboard/bookService";
import Projects from "./pages/dashboard/projects";
import ProjectCardContent from "./pages/dashboard/projects/projectCardContent";
import {
  AdminLogin,
  AdminDashboard,
  Notifications,
  BusinessSuite,
  Dispute,
  SuperAdminDispute,
} from "./admin/pages";
// import { Dispute } from "./admin/pages";
import DisputeReview from "./admin/pages/dispute/disputeReview";
import DisptudeAndConflictLogin from "./admin/pages/dispute/dispute&conflictResolutionLogin";
import ProtectedRoute from "./admin/components/protectedRoute";
import ProtectedRouteDispute from "./admin/pages/dispute/dispute&conflictResolutionLogin";
import ConflictResolutionLogin from "./admin/pages/dispute/conflictResolutonLogin";

import DisputeReviewSupreAdmin from "./admin/pages/super-admin_dispute/dispute/disputeReview";

import VendorDashboardAlerts from "./pages/vendor-dashboard/_pages/alerts";
import VendorDashboardEarnings from "./pages/vendor-dashboard/_pages/earnings";
import VendorDashboardProfile from "./pages/vendor-dashboard/_pages/profile";
import VendorDashboardStats from "./pages/vendor-dashboard/_pages/stats";
import VendorDashboardOffers from "./pages/vendor-dashboard/_pages/offers";
import VendorDashboardSettings from "./pages/vendor-dashboard/_pages/settings";
import VendorDashboardSupport from "./pages/vendor-dashboard/_pages/support";

import TrackDispute from "./pages/dashboard/projects/projectCardContent/desktopProject/checkWork/checkworkModal/dispute/trackdispute";
import DisputeCard from "./pages/dashboard/projects/projectCardContent/desktopProject/checkWork/checkworkModal/dispute/disput-cards";
import Alert from "./pages/dashboard/alerts";
import SummaryReportMobile from "./pages/dashboard/projects/projectCardContent/mobileView/summary-report";
import ProjectExtention from "./pages/dashboard/projects/project_extention";
import Jobs from "./pages/dashboard/jobs";

import TrackJobs from "./pages/dashboard/jobs/components/track-jobs";
import JobMiddleScreeen from "./pages/dashboard/jobs/components/job-middleScreen";
import JobDetails from "./pages/dashboard/jobs/components/job-details";
import JobApplication from "./pages/dashboard/jobs/components/job-application";
import HireVendors from "./pages/dashboard/jobs/components/hired-vendors";





function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1); // increase current steps to move to next form step
  };
  // const adminLogin = useSelector((state) => state?.login.states.loginDetails);
  useEffect(() => {
    if (location.pathname !== "/signup") {
      dispatch(category("category"));
      setCurrentStep(0);
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/vendor" element={<BeAVendor />} />
        <Route
          path="/signup"
          element={<Signup currentStep={currentStep} handleNextStep={handleNextStep} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-otp" element={<EnterOtp />} />
        <Route path="/create-password" element={<CreatePassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/vendor-dashboard/home" element={<VendorDashboardHome />} />
        <Route path="/vendor-dashboard/jobs" element={<VendorDashboardJobs />} />
        <Route path="/vendor-dashboard/chat" element={<VendorDashboardChat />} />
        <Route path="/vendor-dashboard/projects" element={<VendorDashboardProjects />} />
        <Route path="/vendor-dashboard/alerts" element={<VendorDashboardAlerts />} />
        <Route path="/vendor-dashboard/earnings" element={<VendorDashboardEarnings />} />
        <Route path="/vendor-dashboard/profile" element={<VendorDashboardProfile />} />
        <Route path="/vendor-dashboard/stats" element={<VendorDashboardStats />} />
        <Route path="/vendor-dashboard/offers" element={<VendorDashboardOffers />} />
        <Route path="/vendor-dashboard/settings" element={<VendorDashboardSettings />} />
        <Route path="/vendor-dashboard/support" element={<VendorDashboardSupport />} />
        <Route path="/signup/personal-details" element={<PersonalDetails />} />
        <Route path="/signup/service-details" element={<ServiceDetails />} />
        <Route path="/signup/pricing-details" element={<PricingDetails />} />
        <Route path="/signup/catalog&profile" element={<CatalogAndPicture />} />
        <Route path="/signup/setup&complete" element={<Proceed />} />
        <Route path="/service" element={<DashService />} />
        <Route path="/allRecomendedVendors" element={<AllReconmmendedVendors />} />
        <Route path="/cardContent/:id" element={<CardContent />} />
        <Route path="/chat" element={<Chats />} />
        <Route path="/requestServices" element={<Request />} />
        <Route path="/serviceRequest" element={<ServiceRequest />} />
        <Route path="/bookingProcess" element={<BookingProcess />} />
        <Route path="/bookservice" element={<BookService />} />
        <Route path="admin">
          <Route path="" element={<AdminLogin />} />
          {/* <Route element={<ProtectedRoute />}> */}
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="dispute&resolution" element={<SuperAdminDispute />} />
          <Route path="/admin/dispute&resolution/disputereview/:id" element={<DisputeReviewSupreAdmin />} />
          <Route path="business&suite" element={<BusinessSuite />} />
          {/* </Route> */}
          <Route element={<ProtectedRoute />}>
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="dispute&resolution" element={<SuperAdminDispute />} />
            <Route path="business&suite" element={<BusinessSuite />} />
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="dispute&resolution" element={<SuperAdminDispute />} />
          <Route path="/admin/dispute&resolution/disputereview/:id" element={<DisputeReviewSupreAdmin />} />
          <Route path="business&suite" element={<BusinessSuite />} />
          </Route>

        </Route>
        <Route path="/projects" element={<Projects />} />
        <Route path="/projectCardContent/:id" element={<ProjectCardContent />} />
        <Route path="/bookservice" element={<BookService />} />
        <Route path="dispute">
          <Route path="" element={<ConflictResolutionLogin />} />
          <Route path="/dispute&resolution/login" element={<ProtectedRouteDispute />} />
          <Route path="dashboard" element={<Dispute />} />
          <Route path="dashboard/disputereview/:id" element={<DisputeReview />} />
        </Route>
        <Route path="/projects" element={<Projects />} />
        <Route path="/projectCardContent/:id" element={<ProjectCardContent />} />
        <Route path="/project/track-dispute" element={<TrackDispute />} />
        <Route path="/project/dispute-card" element={<DisputeCard />} />
        <Route path="/alerts" element={<Alert />} />
        <Route path="/summary-report" element={<SummaryReportMobile />} />
        <Route path="/project-extention" element={<ProjectExtention />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/jobs/track-jobs" element={<TrackJobs />} />
        <Route path="/jobs/:id" element={<JobMiddleScreeen />} />
        <Route path="/jobs/:id/details" element={<JobDetails />} />
        <Route path="/jobs/:id/application" element={<JobApplication />} />
        <Route path="/jobs/:id/hired-vendor" element={<HireVendors />} />
        
      </Routes>
    </>
  );
}

export default App;
