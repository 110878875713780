import { createSlice } from "@reduxjs/toolkit";

const initialState = {
states:{
   accessToken: null,
}
};
export const tokenSlice = createSlice({
  name: "token",
  initialState: initialState,
  reducers: {
    setAccessToken: (state, action) => {
      state.states.accessToken = action.payload;
    },

    clearAccessToken(state) {
      state.accessToken = null;
    },
    
  },
  
});

export const { setAccessToken,clearAccessToken } = tokenSlice.actions;
