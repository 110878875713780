import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SuccessModalDispute from "../../../sucessModal";
import Checkbox from "./ChexBoxes";
import GivePartialToVendorAndConsumer from "./givePartialAward";

const ResolutionDecision = ({ reviewData = {} }) => {
  const [active, setActive] = useState(0);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [resolutionDecision, setResolutionDecision] = useState({
    disputeId: reviewData?.dispute_id || "",
    decision: "",
    percentageToConsumer: 0,
    percentageToVendor: 0,
    adminResponse: "",
  });
  const [message, setMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    setResolutionDecision((prev) => ({
      ...prev,
      disputeId: reviewData?.dispute_id || "",
    }));
  }, [reviewData]);

  const handleActive = (value) => {
    setActive(value);
    switch (value) {
      case 1:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "consumer",
          percentageToConsumer: 100,
          percentageToVendor: 0,
        }));
        setMessage("Fully awarded to consumer");
        break;
      case 2:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "vendor",
          percentageToConsumer: 0,
          percentageToVendor: 100,
        }));
        setMessage("Fully awarded to vendor");
        break;
      case 3:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "partial",
          percentageToConsumer: 0,
          percentageToVendor: 0,
        }));
        setMessage(""); // Clear message for partial
        break;
      default:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "",
          percentageToConsumer: 0,
          percentageToVendor: 0,
        }));
        setMessage("");
    }
  };

  useEffect(() => {
    if (reviewData?.createdAt && reviewData?.vendor_response === null) {
      const createdAt = new Date(reviewData.createdAt);
      const now = new Date();
      const hoursDifference = Math.abs(now - createdAt) / 36e5;

      if (hoursDifference > 24) {
        setIsButtonEnabled(true);
      }
    }
  }, [reviewData]);

  const handlePartialChange = (e) => {
    const { name, value } = e.target;
    setResolutionDecision((prev) => ({
      ...prev,
      [name]: value ? parseInt(value, 10) : 0,
    }));
  };

  const accessToken = useSelector((state) => state?.token.states.accessToken)

  const fetchResolutionDecision = async (e) => {
    e.preventDefault();

    const { percentageToConsumer, percentageToVendor } = resolutionDecision;

    if (
      percentageToConsumer + percentageToVendor !== 100 &&
      resolutionDecision.decision === "partial"
    ) {
      alert("The sum of percentages for Consumer and Vendor must equal 100%.");
      return;
    }

    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/handle-disputes`, {
        method: "POST",
        body: JSON.stringify(resolutionDecision),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.clone().json();
          throw new Error(errorData.message || "Unknown error occurred");
        } catch (jsonError) {
          const text = await response.clone().text();
          console.error("Response text:", text);
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        }
      }

      const responseData = await response.json();
      setMessage("Decision submitted successfully");
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error:", error.message);
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
      <h3 className="text-[20px] font-medium text-[#002D3A]">Resolution Decision</h3>
      <form action="">
        <div className="mt-4 flex gap-10">
          <Checkbox handleActive={handleActive} active={active} />
        </div>
        <div className="">
          {active === 3 && (
            <GivePartialToVendorAndConsumer
              percentageToConsumer={resolutionDecision.percentageToConsumer}
              handlePartialChange={handlePartialChange}
              percentageToVendor={resolutionDecision.percentageToVendor}
            />
          )}
        </div>
        <h3 className="mt-7 text-[20px]">Reason for resolution (optional)</h3>
        <textarea
          name="reason"
          value={resolutionDecision.adminResponse}
          id=""
          onChange={(e) =>
            setResolutionDecision((prev) => ({ ...prev, adminResponse: e.target.value }))
          }
          cols="70"
          rows="5"
          className="mt-1 rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
        ></textarea>
        <div className="mt-16">
          <button
            className={`w-[35%] rounded-[5px] ${reviewData?.status === "unresolved" ? "bg-[#b8eaf8]" : "bg-[#0096C1]"} px-4 py-2 text-[18px] text-white`}
            onClick={fetchResolutionDecision}
            type="submit"
            disabled={reviewData?.status === "unresolved" && !isButtonEnabled}
          >
            Send
          </button>
        </div>
      </form>
      {showSuccessModal && (
        <SuccessModalDispute message={message} adminResponse={resolutionDecision.adminResponse} />
      )}
    </div>
  );
};

export default ResolutionDecision;
