
import React, { useState, useMemo } from "react";
import { Button } from "../../../../../components";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Dropdown } from "../../../../../constants/images";
import { useDispatch } from "react-redux";
import { setModalOverlay } from "../../../../../redux/slice/modalOverlay";
import { parseProjectDates } from "../../../../../utils/parsedProject";
import { filterProjectDataByDateRange } from "../../../../../utils/dateFilter";

const ProjectChart = ({
  totalOngoingProjects,
  totalCanceledRequests,
  totalCompletedProjects,
  onGoingJobs,
  completedJobs,
  cancelledJobs,
}) => {
  const dispatch = useDispatch();
  const [selectedPeriod, setSelectedPeriod] = useState("This week");
  const [openSelect, setOpenSelect] = useState(false);

  const projects = [...onGoingJobs, ...completedJobs, ...cancelledJobs];

  const parsedProjects = useMemo(() => parseProjectDates(projects), [projects]);


  const filteredProjects = useMemo(() => {
    const dateRange =
      selectedPeriod === "This week" ? "week" : selectedPeriod === "This month" ? "month" : "year";
    return filterProjectDataByDateRange(parsedProjects, dateRange);
  }, [parsedProjects, selectedPeriod]);

  const chartData = useMemo(() => {
    const result = {};

    filteredProjects.forEach((project) => {
      const dateKey = project?.start_date.toLocaleDateString("en-GB");

      if (!result[dateKey]) {
        result[dateKey] = { name: dateKey, ongoing: 0, completed: 0, cancelled: 0 };
      }

      if (project?.status === "ongoing") {
        result[dateKey].ongoing += 1;
      } else if (project.status === "completed") {
        result[dateKey].completed += 1;
      } else if (project.status === "cancelled") {
        result[dateKey].cancelled += 1;
      }
    });

    return Object.values(result);
  }, [filteredProjects]);

  const handleSelect = () => setOpenSelect((prev) => !prev);

  return (
    <div className="mx-auto mb-10 flex w-full rounded-[10px] bg-primary py-4">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between px-10 py-5">
          <span className="font-medium text-secondary md:text-lg">Projects</span>
          <div className="relative flex items-center gap-x-2">
            <div
              onClick={handleSelect}
              className="flex w-[190px] items-center justify-between rounded-[5px] border border-[#AEAEAE] px-5 py-2 text-base font-medium text-lightgray hover:cursor-pointer"
            >
              <span>{selectedPeriod}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute left-0 top-[100%] w-full animate-slide_up rounded-[10px] bg-primary px-3 py-4">
                <div className="flex flex-col items-center gap-y-2 text-base font-medium text-lightgray">
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This week")}
                  >
                    This week
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This month")}
                  >
                    This month
                  </span>
                  <span
                    className="hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("This year")}
                  >
                    This year
                  </span>
                  <span
                    className="text-lightblue hover:cursor-pointer"
                    onClick={() => setSelectedPeriod("Custom range")}
                  >
                    Custom range
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr className="w-full px-0" color="#AEAEAE" />
        <div className="flex w-full gap-x-8 p-10">
   
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#279F51]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Ongoing projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalOngoingProjects}
              </span>
            </div>
          </div>
    
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#623CEA]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Completed projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalCompletedProjects}
              </span>
            </div>
          </div>
       
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#C12B00]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Cancelled projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalCanceledRequests}
              </span>
            </div>
          </div>
        </div>

      
        <BarChart
          width={1200}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 50,
            left: 50,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="ongoing" barSize={20} stackId="a" fill="#279F51" />
          <Bar dataKey="completed" barSize={20} stackId="a" fill="#623CEA" />
          <Bar dataKey="cancelled" barSize={20} stackId="a" fill="#C12B00" />
        </BarChart>
      </div>
    </div>
  );
};

export default ProjectChart;
