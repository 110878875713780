import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import AllDispute from "./shared/allDispute";
import CardDisputes from "./shared/progressCard";
import ResolvedDispute from "./shared/resolvedData";
import ResolvingDispute from "./shared/resolvingDispute";
import UnresolvedDispute from "./shared/unresolvedDispute";

const SuperAdminDisputeMain = () => {
  const searchParams = useSelector((state) => state.search.disputeSearchInput);
  const [active, setActive] = useState(0);
  const [allDisputes, setAllDispute] = useState(null);
  const [unresolvedDisputes, setUnresolvedDispute] = useState({});
  const [reslovingDisputes, setReslovingDispute] = useState({});
  const [reslovedDisputes, setReslovedDispute] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const allTheCardDetails = [
    {
      id: 1,
      text: "Unresolved Disputes",
      percentage: (allDisputes?.length * unresolvedDisputes.unresolvedCount) / 100,
      length: unresolvedDisputes.unresolvedCount,
      textColor: "#002D3A",
      pathColor: "#FFA000",
      trailColor: "#FFE5D3",
    },
    {
      id: 2,
      text: "Resolving Disputes",
      percentage: (allDisputes?.length * reslovingDisputes.resolvingCount) / 100,
      length: reslovingDisputes.resolvingCount,
      textColor: "#002D3A",
      pathColor: "#623CEA",
      trailColor: "#DAD7FE",
    },
    {
      id: 3,
      text: "Resolved Disputes",
      percentage: (allDisputes?.length * reslovingDisputes.resolvingCount) / 100,
      length: reslovedDisputes.resolvedCount,
      textColor: "#002D3A",
      pathColor: "#279F51",
      trailColor: "#E2FBD7",
    },
  ];

  const accessToken = useSelector((state) => state?.token.states.accessToken)

  function handleActive(id) {
    setActive(id);
  }
  useEffect(() => {
    if (searchParams) {
      const filterdDispute = allDisputes.filter((disputes) =>
        disputes.status.includes(searchParams),
      );
      setAllDispute(filterdDispute);
    } else {
      fetchDisputes();
    }
  }, [searchParams]);

  const fetchDisputes = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/disputes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();

      setAllDispute(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchUnresolvedDisputes = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/disputes/count-unresolved`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.json();
      console.log(data);
      setUnresolvedDispute(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchResolvingDisputes = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/disputes/count-resolving`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.json();

      setReslovingDispute(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchResolvedDisputes = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/disputes/count-resolved`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const data = await response.json();

      setReslovedDispute(data);
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  useEffect(() => {
    fetchDisputes();
    fetchUnresolvedDisputes();
    fetchResolvingDisputes();
    fetchResolvedDisputes();
  }, []);

  function out(e) {
    e.target.id === "clickout" && setActive(0);
  }

  const fakeArray = [1, 2, 3];

  return (
    <div id="clickout" onClick={out} className="mb-16 min-h-screen w-full">
      <div className="mt-7 flex w-full gap-7 px-10">
        {allDisputes
          ? allTheCardDetails.map((datas) => (
              <CardDisputes
                key={datas.id}
                active={active}
                id={datas.id}
                {...datas}
                onClick={() => handleActive(datas.id)}
              />
            ))
          : fakeArray.map((f, i) => <Skeleton height={130} width={"30vw"} key={i} />)}
      </div>
      <div className="mx-10 mt-10 rounded-b-[15px] rounded-t-[15px] bg-white drop-shadow-md">
        <div className="rounded-t-[15px] bg-[#AEAEAE] px-10 py-3">
          <h3 className="text-[20px] font-medium text-[#002D3A]">
            {active === 0
              ? "All Disputes"
              : active === 1
                ? "Unresolved Disputes"
                : active === 2
                  ? "Resolving Disputes"
                  : "Resolved Disputes"}
          </h3>
        </div>
        {active === 0 && (
          <AllDispute
            allDisputes={allDisputes && allDisputes}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
        {active === 1 && (
          <UnresolvedDispute
            unresolvedDisputes={unresolvedDisputes?.unresolvedDisputes}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}

        {active === 2 && (
          <ResolvingDispute
            reslovingDisputes={reslovingDisputes?.resolvingDisputes}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
        {active === 3 && (
          <ResolvedDispute
            resolvedDisputes={reslovedDisputes?.resolvedDisputes}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
      </div>
    </div>
  );
};

export default SuperAdminDisputeMain;
