import React, { useState } from "react";
import { left, linkIcon } from "../../../../../../constants/images";
import { Link } from "react-router-dom";

const SupportContent = () => {
  const [showContact, setShowContact] = useState(false);
  const [showDispute, setShowDispute] = useState(false);
  return (
    <div className="relative h-[120vh] max-w-full items-start justify-center pl-0 pr-0 pt-[8rem] md:float-right md:mx-auto md:ml-auto md:mr-0 md:block md:w-[81%] md:pt-40">
      <Link to="/vendor-dashboard/home" className="relative block md:hidden">
        <img src={left} alt="go back" className="absolute left-4 top-2 w-[10px]" />
      </Link>
      <div className="flex w-full flex-col items-start justify-between px-6 md:px-20">
        <div className="flex w-full justify-center">
          <span className="mx-4 text-center text-xl font-medium text-secondary md:mx-0 md:text-[25px]">
            Help and support
          </span>
        </div>
        <div className="mt-8 flex w-full flex-col gap-y-6 md:w-1/2">
          <Link to={"#"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">FAQs</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>
          <Link to={"/about-us"} className="rounded-[5px] border border-[#AEAEAE] py-2">
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">About us</span>
              <img src={linkIcon} alt="link" className="" />
            </div>
          </Link>

          <div
            className="relative rounded-[5px] border border-[#AEAEAE] py-2"
            onClick={() => setShowContact(!showContact)}
          >
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">Contact us</span>
              <img
                src={left}
                alt=""
                className={`${showContact ? "rotate-90" : "rotate-0"} transition-transform ease-out`}
              />
            </div>
            {showContact && <div></div>}
          </div>

          <div
            className="relative rounded-[5px] border border-[#AEAEAE] py-2"
            onClick={() => setShowDispute(!showDispute)}
          >
            <div className="flex items-center justify-between px-4 py-2 text-sm md:text-base">
              <span className="text-sm font-medium text-[#181818] md:text-lg">
                Dispute resolution
              </span>
              <img
                src={left}
                alt=""
                className={`${showDispute ? "rotate-90" : "rotate-0"} transition-transform ease-out`}
              />
            </div>
            {showDispute && <div></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportContent;
