import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { Spinner } from "../../../../../../constants/images";
import { setAddNewAgent } from "../../../../../../redux/slice/modalOverlay";

const AddNewAgent = ({ fetchAllAgent }) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    company_name: "",
    email: "",
  });
  const [errorState, setErrorState] = useState({
    nameError: "",
    company_nameError: "",
    emailError: "",
  });

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let isError = false;
    const errors = {
      nameError: "",
      company_nameError: "",
      emailError: "",
    };

    if (!details.name) {
      isError = true;
      errors.nameError = "Please enter agent name";
    }
    if (!details.company_name) {
      isError = true;
      errors.company_nameError = "Please enter the company name";
    }
    if (!details.email) {
      isError = true;
      errors.emailError = "Please enter your email";
    }

    setErrorState(errors); // Directly set the error state
    return isError;
  };

  const handleAddAgent = async () => {
    const error = validate();
    if (!error) {
      setLoading(true);
      try {
        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/add-agent`, {
          method: "POST",
          body: JSON.stringify(details),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          let errorData;
          try {
            errorData = await response.clone().json();
            throw new Error(errorData.message || "Unknown error occurred");
          } catch (jsonError) {
            const text = await response.clone().text();
            console.error("Response text:", text);
            throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
          }
        }

        const data = await response.json();
        // Handle success (optional: show success modal, etc.)
        setTimeout(() => {
          fetchAllAgent();
          setLoading(false);
          dispatch(setAddNewAgent(false)); // Close modal after success
        }, 3000); // Display success modal for 3 seconds
      } catch (error) {
        console.log("Error adding agent", error);
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    dispatch(setAddNewAgent(false));
  };

  return (
    <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-10 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        X
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className="py-2 text-start text-xl font-semibold text-secondary">Add New Agent</span>
        <div className="flex flex-col">
          <label htmlFor="email" className="text-base font-normal text-[#181818]">
            Email
          </label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="email"
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          <span className="text-start text-sm text-red-500">{errorState.emailError}</span>
        </div>
        <div className="flex flex-col">
          <label htmlFor="name" className="text-base font-normal text-[#181818]">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name the agent"
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          <span className="text-start text-sm text-red-500">{errorState.nameError}</span>
        </div>
        <div className="flex flex-col">
          <label htmlFor="company_name" className="text-base font-normal text-[#181818]">
            Company name
          </label>
          <input
            type="text"
            name="company_name"
            id="company_name"
            placeholder="Name of the company"
            onChange={handleChange}
            className="w-full border border-[#AEAEAE] px-2 py-4 outline-none"
          />
          <span className="text-start text-sm text-red-500">{errorState.company_nameError}</span>
        </div>

        <div className="mt-10 w-full">
          <Button
            className="flex w-full items-center justify-center"
            onClick={handleAddAgent}
            disabled={loading}
          >
            {loading ? <img src={Spinner} alt="loading" className="w-[5%]" /> : "Add agent"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNewAgent;
