import React, { useEffect, useState } from "react";
import { Button, SuccessModal } from "../../../../../../components";
import { setEditAdvert, setRefreshAd } from "../../../../../../redux/slice/modalOverlay";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../../../../../constants/images";

const EditAdvertModal = () => {
  const dispatch = useDispatch();
  const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const advertId = useSelector((state) => state.modalOverlay.states.advertId);
  const [selectedImage, setSelectedImage] = useState();
  const [advertName, setAdvertName] = useState();
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const getAdvertDetails = async () => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/adverts/${advertId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      // console.log(data);
      if (response?.ok) {
        setImageError(null);
        setAdvertName(data.name);
        setSelectedImage(data.advert_image_url);
      } else {
        setImageError("Failed to get advert, please try again");
      }
    } catch (error) {
      console.log(error);
      setImageError("Failed to get advert, please try again");
    }
  };

  useEffect(() => {
    getAdvertDetails();
  }, []);

  const handleNameChange = (event) => {
    const name = event.target.value.trim();
    setAdvertName(name);

    if (name.length === 0) {
      setNameError("Advert name is required.");
    } else {
      setNameError("");
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

    if (file && allowedFormats.includes(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      setImageName(file.name);
      setImageError("");
    } else {
      setSelectedImage(null);
      setImageName("");
      setImageError("Invalid file format. Please select a JPG, JPEG, PNG, or SVG file.");
    }
  };
  const handleEditAdvert = async () => {
    if (advertName === "") {
      setNameError("Advert name is required.");
      return;
    }

    if (selectedImage === null) {
      setImageError("Image is required.");
      return;
    }

    try {
      setImageError("");
      setLoading(true);
      const nameResponse = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/advert/${advertId}/name`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ name: advertName }),
        },
      );
      console.log(nameResponse);

      const formData = new FormData();
      formData.append("name", selectedImage);
      const imageResponse = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/adverts-image/${advertId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        },
      );
      if (nameResponse?.ok || imageResponse?.ok) {
        setImageError(null);
        setLoading(false);
        dispatch(setRefreshAd(true));
        setSuccess(true);
      } else {
        setImageError("Error in editing advert");
        setLoading(false);
      }
    } catch (error) {
      setImageError("Error in editing advert");
      console.log(error);
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(setEditAdvert(false));
  };

  return (
    <>
      {!success && (
        <div className="relative rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
          <div
            className="absolute right-10 top-2 text-2xl hover:cursor-pointer"
            onClick={handleClose}
          >
            X
          </div>
          <div className="flex flex-col gap-y-3 px-8">
            <span className={`py-2 text-start text-xl font-semibold text-secondary`}>
              Edit Advert
            </span>
            <div className="flex flex-col">
              <label htmlFor="referral_name" className="text-base font-normal text-[#181818]">
                Name
              </label>
              <input
                type="text"
                name="referral_name"
                id="referral_name"
                value={advertName}
                onChange={handleNameChange}
                className="w-full border border-[#AEAEAE] px-4 py-2 outline-none"
              />
              {nameError && <span className="text-red-500">{nameError}</span>}
            </div>
            <div className="flex flex-col gap-y-2">
              <span className="text-base font-normal text-[#181818]">Image</span>
              <img src={selectedImage} alt="image" className="w-[30%]" />
              <span className="text-sm text-lightblue">{imageName}</span>
              <label htmlFor="changeImage" className="cursor-pointer text-sm text-lightblue">
                Change image
              </label>

              <input
                type="file"
                name="changeImage"
                id="changeImage"
                className="hidden"
                accept=".jpg,.jpeg,.png,.svg"
                onChange={handleImageChange}
              />
              {<span className="text-base text-red-500">{imageError}</span>}
            </div>
            <div className="mt-10 flex w-full items-center gap-x-2">
              <Button className="w-full" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="flex w-full items-center justify-center"
                onClick={handleEditAdvert}
              >
                {loading ? <img src={Spinner} alt="loading" className="w-[13%]" /> : "Update"}
              </Button>
            </div>
          </div>
        </div>
      )}

      {success && (
        <SuccessModal
          title={"Advert Edited Successfully"}
          buttonText={"OK"}
          onClick={handleClose}
          handleClose={handleClose}
        />
      )}
    </>
  );
};
export default EditAdvertModal;
